import React from "react";

const Bio = () => {
    const [topImage, setTopImage] = React.useState(require("../assets/images/1651682643858.png"));
    const [image1, setImage1] = React.useState(require("../assets/images/img-1.png"));
    const [image2, setImage2] = React.useState(require("../assets/images/img-2.png"));
    const [image3, setImage3] = React.useState(require("../assets/images/img-3.png"));

    const handleImageClick = (currentImageSrc: string, setImageSrc: React.Dispatch<React.SetStateAction<string>>) => {
        const topImageSrc = topImage;
        setTopImage(currentImageSrc);
        setImageSrc(topImageSrc);
    }

    return(
        <div className="layout_padding px-12 lg:px-44 mb-16">
            <div>
                <h1 className="text-[25px] md:text-[40px] font-bold mt-3 mb-16"><strong>Jeroen Egelmeers</strong> | Prompt Engineering Advocate & Trainer</h1>
            </div>
            <div className="flex max-container mx-auto">
                <div className={"pb-20 flex-grow"}>
                    <div className="text-left">
                        <p className={"mb-6"}>Born in 1991, Jeroen Egelmeers grew up in the quiet countryside of Wanroij, Noord-Brabant, Netherlands. From a young age, he showed a passion for technology, even though school didn't initially capture his interest. It was during his studies in Interaction Design at de Eindhovense school (now: SintLucas)(2008-2012) that he realized his true passion lay in the technology driving the design, leading him to pursue a degree in Informatics at Avans University of Applied Sciences (2012-2016).</p>
                        <p className={"mb-6"}>After graduation, Jeroen began his career at Sogeti as a Full Stack Developer, specializing in technologies like Java, Angular, React, and later transitioning into leadership roles across various projects in major Dutch organizations such as Philips, ASML, and Bovemij.</p>
                        <p className={"mb-6"}>As of November 2023, Jeroen has embraced a new role as a Prompt Engineering Advocate, where he helps companies unlock the full potential of generative AI through training, workshops, and corporate strategies. He also shares his expertise on the global stage, speaking at some of the largest conferences in the world, such as Devoxx Belgium, ODSC, Prompt Engineering Conference, and Microsoft Envision.</p>
                        <p className={"mb-6"}>Jeroen is the founder of the CRAFTING AI Prompts Framework, a pioneering approach that enables organizations to write more effective prompts, driving innovation and efficiency in their use of AI. His passion for giving back is reflected in his work as a trainer, helping people with autism develop careers in software engineering through Capgemini Academy.</p>
                        <p className={"mb-6"}>In his personal life, Jeroen is an avid volleyball player, theater enthusiast, and loves traveling around the world. He’s also a devoted fan of black coffee—no sugar, no milk—and enjoys connecting with his growing network of over 8,000 LinkedIn followers.</p>
                        <p className={"mb-6"}>Ready to explore the future of Generative AI with Jeroen? Reach out and discover how he can help transform your organization through the power of generative AI. You can reach Jeroen via LinkedIn!</p>
                    </div>
                </div>
                <div className="pt-12 hidden lg:block pl-20 relative lg:w-1/2">
                    <div className={"bg-gray-100 shadow-lg rounded-xl overflow-hidden mb-6"}>
                        <img src={topImage} className={"object-cover w-full h-full"} />
                    </div>

                    <div className="flex space-x-4 cursor-pointer">
                        <div className="w-32 h-32 overflow-hidden rounded-xl shadow-lg">
                            <img src={image1} onClick={() => handleImageClick(image1, setImage1)} className="object-cover w-full h-full" />
                        </div>
                        <div className="w-32 h-32 overflow-hidden rounded-xl shadow-lg">
                            <img src={image2} onClick={() => handleImageClick(image2, setImage2)} className="object-cover w-full h-full" />
                        </div>
                        <div className="w-32 h-32 overflow-hidden rounded-xl shadow-lg">
                            <img src={image3} onClick={() => handleImageClick(image3, setImage3)} className="object-cover w-full h-full" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-4 lg:hidden relative mx-auto max-w-[300px]">
                <div className={"bg-gray-100 shadow-lg rounded-xl overflow-hidden mb-6"}>
                    <img src={require("../assets/images/1651682643858.png")} className={"object-cover w-full h-full"} />
                </div>
            </div>
        </div>
    );
}

export default Bio;