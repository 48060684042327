import React, {useEffect} from 'react';
import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "./components/Header";
import Services from "./components/Services";
import AboutMe from "./components/AboutMe";
import Copyright from "./components/Copyright";
import Footer from "./components/Footer";
import Companies from "./components/Companies";
import Blogs from "./components/Blogs";
import CountUp from "react-countup";
import VisibilitySensor from 'react-visibility-sensor';
import {events} from "./objects/events";
import HeaderSmall from "./components/HeaderSmall";

function App() {

    useEffect(() => {
        document.title = "Blogs | Jeroen Egelmeers | Prompt Engineering Advocate | Public Speaker"
    }, []);



  return (
    <div className="App flex flex-col min-h-screen">
        <HeaderSmall headerText="Blogs" />

        <div className="clearfix"></div>

        <Blogs title={"Blogs"} backgroundImage={false} />

        <Footer />
        <Copyright />
    </div>
  );
}

export default App;
