import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";
import {blogs} from "../objects/blogs";
import {useState} from "react";

interface BlogsProps {
    title: string
    backgroundImage: boolean;
    show?: number;
}

const Blogs = ({ title, backgroundImage, show }: BlogsProps) => {
    let displayedBlogs = blogs;

    if (show && show > 0) {
        displayedBlogs.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
        displayedBlogs = displayedBlogs.slice(0, show);
    }

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 9;

    const endIndex = currentPage * itemsPerPage;
    const startIndex = endIndex - itemsPerPage;
    const currentBlogs = displayedBlogs.slice(startIndex, endIndex);

    const totalPages = Math.ceil(displayedBlogs.length / itemsPerPage);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);

        // Scroll to the element with id 'latest-blogs'
        const element = document.getElementById('latest-blogs');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const getPlatformColor = (platform: string) => {
        switch (platform) {
            case 'Sogeti':
                return 'bg-blue-800';
            case 'Sogeti Labs':
                return 'bg-purple-950';
            case 'Medium':
                return 'bg-black';
            case 'LinkedIn':
                return 'bg-blue-600';
            default:
                return 'bg-gray-950';
        }
    }
    return (
        <div className={`layout_padding mb-8 ${backgroundImage && `blog_section`}`}>
            <div className="container">
                <h1 className={`blog_taital bold ${!backgroundImage ? `text-gray-950` : `text-white`}`} id={"latest-blogs"}>{title}</h1>
                <p className={`blog_text ${!backgroundImage ? `text-gray-950` : `text-white`}`}>Jeroen is writing for various platforms about Prompt Engineering. Read the latest blogs below!</p>

                <div className="mt-10">
                    <div className="flex flex-col md:flex-row lg:flex-row flex-wrap justify-center mx-5 space-x-0 md:space-x-5 items-center">
                        {currentBlogs.map((blog, index) => (
                            <div key={index} className={"max-w-[210px] mb-10 bg-black"}>
                                <a href={blog.url} target={"_blank"} title={blog.title} className={`relative block ${backgroundImage ? `hover:shadow-lg hover:shadow-white` : `hover:shadow-lg hover:shadow-black`}`}>
                                    <img src={blog.image} className={"opacity-60"} />

                                    <div className="blog-text font-bold absolute top-[35%] left-0 text-white px-3 py-8 h-12 justify-center flex items-center space-x-2 bg-opacity-80 rounded-tl rounded-br">
                                        {blog.title}
                                    </div>

                                    <div className="absolute top-0 left-0 text-white px-3 py-8 h-12 justify-center flex items-center space-x-2 bg-opacity-80 rounded-tl rounded-br">
                                        {blog.platform &&
                                            <div className={`text-white p-2 rounded ${getPlatformColor(blog.platform)}`}>{blog.platform}</div>
                                        }
                                    </div>

                                    <div className={`absolute bottom-0 right-0 p-2 w-40 h-12 justify-center flex items-center space-x-2 bg-opacity-80 rounded-tl rounded-br bg-black text-white`}>
                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                        <div className={"text-sm"}>{blog.date}</div>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                    {show === undefined &&
                        <div className="flex justify-center space-x-4">
                            {Array.from({length: totalPages}, (_, i) => i + 1).map(page => (
                                <button
                                    key={page}
                                    onClick={() => handlePageChange(page)}
                                    className={`px-4 py-2 rounded-md hover:bg-purple-950 hover:text-white ${page === currentPage ? 'bg-black text-white' : 'bg-white text-black'}`}
                                >
                                    {page}
                                </button>
                            ))}
                        </div>
                    }
                    {show !== undefined &&
                        <div className="readmore_bt"><a href="/blogs">Read More</a></div>
                    }
                </div>
            </div>
        </div>
    );
}

export default Blogs;