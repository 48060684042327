import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

interface Event {
    date: string;
    title: string;
    url: string;
    image: string;
    live: boolean;
    soldOut?: boolean;
    multipleSessions?: number;
}

interface ServicesProps {
    events: Event[];
    title: string;
    description: string;
    showMore?: boolean;
}

const Services = ({ events, title, description, showMore }: ServicesProps) => {

    function isThisWeek(date: string) {
        const eventDate = new Date(date);
        const today = new Date();
        // Set the time parts to 00:00:00
        eventDate.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);

        const nextWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
        return eventDate >= today && eventDate < nextWeek;
    }

    function isToday(date: string) {
        const eventDate = (new Date(date)).setHours(0, 0, 0, 0);
        const today = (new Date()).setHours(0, 0, 0, 0);

        return eventDate == today;
    }

    function isTomorrow(date: string) {
        const eventDate = (new Date(date)).setHours(0, 0, 0, 0);
        const tomorrow = (new Date()).setHours(0, 0, 0, 0) + 86400000;

        return eventDate == tomorrow;
    }

   function pastEvent(date: string) {
        const eventDate = (new Date(date)).setHours(0, 0, 0, 0);
        const currentDate = (new Date()).setHours(0, 0, 0, 0);

        return eventDate < currentDate;
    }

    return(
        <div className="services_section layout_padding">
            <div className="container">
                <h1 className="services_taital mb-6 text-[30px] md:text-[40px]">{title}</h1>
                <p className="services_text px-6">{description}</p>
                <div className="services_section_2">
                    <div className="flex flex-col md:flex-row lg:flex-row flex-wrap justify-center mx-5 space-x-0 md:space-x-5 items-center">
                        {events.map((event, index) => (
                            <div key={index} className={"max-w-[305px] mb-10 rounded flex-grow"}>
                                <a href={event.url} target={"_blank"} className={"bg-inherit block relative rounded-xl hover:shadow-lg hover:shadow-black event-button"}>
                                    <img src={event.image} className={`${pastEvent(event.date) ? 'opacity-50' : ''}`} />

                                    <div className={`absolute top-0 left-0 text-white px-3 py-8 h-12 justify-center flex items-center space-x-2 rounded-tl rounded-br`}>
                                        {event.live ?
                                            <div className={`bg-purple-950 text-white p-2 rounded`}>In-person</div> :
                                            <div className={`bg-green-500 text-white p-2 rounded`}>Online</div>
                                        }
                                    </div>

                                    {(event.soldOut) &&
                                        <div
                                            className="absolute top-0 right-0 text-white px-3 py-8 h-12 justify-center flex items-center space-x-2 bg-opacity-80 rounded-tl rounded-br">
                                            <div className="bg-red-600 text-white p-2 rounded">Sold out!</div>
                                        </div>
                                    }

                                    {(event?.multipleSessions) &&
                                        <div
                                            className={`absolute top-0 text-white px-3 py-8 h-12 justify-center flex items-center space-x-2 bg-opacity-80 rounded-tl rounded-br ${event.soldOut ? 'right-24' : 'right-0'}`}>
                                            <div className="bg-sky-700 text-white p-2 rounded">{event.multipleSessions} sessions</div>
                                        </div>
                                    }

                                    {pastEvent(event.date) ?
                                        <div
                                            className="absolute bottom-0 left-0 text-white px-2 py-7 h-12 justify-center flex items-center space-x-2 bg-opacity-80">
                                            <div className="bg-red-600 text-white rounded-tl rounded-lg p-2 opacity-80">Event concluded</div>
                                        </div> :
                                        isTomorrow(event.date) ?
                                            <div
                                                className="absolute bottom-2 left-3 text-white h-11 justify-center flex items-center bg-opacity-80">
                                                  <span>
                                                    <span className="spark mask-gradient animate-flip before:animate-rotate absolute inset-0 -mx-0.5 -my-0.5 overflow-hidden rounded-lg [mask:linear-gradient(white,_transparent_50%)] before:absolute before:aspect-square before:w-[200%] before:rotate-[-90deg] before:bg-[conic-gradient(from_0deg,transparent_0_340deg,white_360deg)] before:content-[''] before:[inset:0_auto_auto_50%] before:[translate:-50%_-15%]" />
                                                  </span>

                                                <div className="inset-[1px] bg-green-600 z-10 text-white rounded-tl rounded-lg p-2 rounded opacity-100">Tomorrow</div>
                                            </div>
                                            :
                                        isToday(event.date) ?
                                            <div
                                                className="absolute bottom-2 left-3 text-white h-11 justify-center flex items-center bg-opacity-80">
                                                <div
                                                    className="animate-ping absolute w-11 h-8 inline-flex rounded-lg bg-white opacity-75"></div>

                                                <div className="inset-[1px] bg-green-600 z-10 text-white rounded-lg p-2 rounded opacity-100">Today</div>
                                            </div> :
                                        isThisWeek(event.date) &&
                                        <div
                                            className="absolute bottom-0 left-0 text-white px-2 py-7 h-12 justify-center flex items-center space-x-2 bg-opacity-80">
                                            <div className="bg-green-600 text-white rounded-tl rounded-lg p-2 rounded opacity-100">This week!</div>
                                        </div>
                                    }

                                    <div className="absolute top-0 left-0 text-white px-3 py-8 h-12 justify-center flex items-center space-x-2 bg-opacity-80 rounded-tl rounded-br">
                                        {event.live ?
                                            <div className="bg-purple-950 text-white p-2 rounded">In-person</div> :
                                            <div className="bg-green-500 text-white p-2 rounded">Online</div>
                                        }
                                    </div>

                                    <div className={`absolute bottom-2 right-1 p-2 w-[48%] h-10 justify-center flex items-center space-x-2 bg-opacity-80 rounded-tl rounded-lg bg-black ${pastEvent(event.date) ? 'text-gray-500' : 'text-white'}`}>
                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                        <div className={"text-sm"}>{event.date}</div>
                                        { isToday(event.date) &&
                                            <>
                                                <div
                                                    className="animate-ping absolute top-0 right-0 w-5 h-5 inline-flex rounded-full bg-red-600 opacity-75"></div>
                                                <div className="absolute top-1 right-1 w-3 h-3 inline-flex rounded-full bg-red-600 opacity-75"></div>
                                            </>
                                        }
                                    </div>

                                    <div className="btn_main relative">
                                        <span>{event.title}</span>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                    {showMore &&
                        <div className="readmore_bt -m-5"><a href="/events">More Events</a></div>
                    }
                </div>
            </div>
        </div>
    );
}

export default Services;