import {platform} from "os";

enum platforms {
    SogetiLabs = "Sogeti Labs",
    Medium = "Medium",
    LinkedIn = "LinkedIn",
    Sogeti = "Sogeti"
}

export const blogs = [
    {
        title: "A guide to Adversarial Prompts",
        platform: platforms.SogetiLabs,
        url: "https://labs.sogeti.com/the-art-of-prompt-crafting/",
        date: "Sep 06, 2024",
        image: require("../assets/images/blog-pe.png"),
    },
    {
        title: "Prompt Libraries, Components and Recipes for Sustainable Prompt Engineering",
        platform: platforms.SogetiLabs,
        url: "https://labs.sogeti.com/libraries-components-and-recipes-for-sustainable-prompt-engineering/",
        date: "Mar 11, 2024",
        image: require("../assets/images/blog-pe.png"),
    },
    {
        title: "Prompt Engineering: Context window and prompting techniques",
        platform: platforms.SogetiLabs,
        url: "https://labs.sogeti.com/part-4-the-art-of-prompt-crafting-context-window-and-prompting-techniques/",
        date: "Feb 12, 2024",
        image: require("../assets/images/blog-pe.png"),
    },
    {
        title: "The art of Prompt Crafting – Interactive prompting and the role of acting roles",
        platform: platforms.SogetiLabs,
        url: "https://labs.sogeti.com/the-art-of-prompt-crafting-interactive-prompting-and-the-role-of-acting-roles/",
        date: "Jan 30, 2024",
        image: require("../assets/images/blog-pe.png"),
    },
    {
        title: "The art of Prompt Crafting – The framework",
        platform: platforms.SogetiLabs,
        url: "https://labs.sogeti.com/the-art-of-prompt-crafting-the-framework/",
        date: "Jan 22, 2024",
        image: require("../assets/images/blog-pe.png"),
    },
    {
        title: "The art of Prompt Crafting – Introduction",
        platform: platforms.SogetiLabs,
        url: "https://labs.sogeti.com/the-art-of-prompt-crafting-introduction/",
        date: "Jan 11, 2024",
        image: require("../assets/images/blog-pe.png"),
    },
    {
        title: "The art of Prompt Crafting - Part Three",
        platform: platforms.Medium,
        url: "https://medium.com/@jeroenegelmeers/the-art-of-prompt-crafting-part-three-0484bbb6af0c",
        date: "Oct 26, 2023",
        image: require("../assets/images/blog-pe.png"),
    },{

        title: "The art of Prompt Crafting - Part Three",
        platform: platforms.LinkedIn,
        url: "https://www.linkedin.com/pulse/art-prompt-crafting-part-three-jeroen-egelmeers-eafie/",
        date: "Oct 25, 2023",
        image: require("../assets/images/blog-pe.png"),
    },
    {
        title: "The art of Prompt Crafting - Part Two",
        platform: platforms.Medium,
        url: "https://medium.com/@jeroenegelmeers/the-art-of-prompt-crafting-part-two-93c9886ccfab",
        date: "Sep 11, 2023",
        image: require("../assets/images/blog-pe.png"),
    },
    {
        title: "The art of Prompt Crafting - Part Two",
        platform: platforms.LinkedIn,
        url: "https://www.linkedin.com/pulse/art-prompt-crafting-part-two-jeroen-egelmeers/",
        date: "Aug 29, 2023",
        image: require("../assets/images/blog-pe.png"),
    },
    {
        title: "The art of Prompt Crafting",
        platform: platforms.Medium,
        url: "https://medium.com/@jeroenegelmeers/the-art-of-prompt-crafting-4bc2b9e22624",
        date: "Jun 07, 2023",
        image: require("../assets/images/blog-pe.png"),
    },
    {
        title: "The art of Prompt Crafting",
        platform: platforms.LinkedIn,
        url: "https://www.linkedin.com/pulse/art-prompt-crafting-jeroen-egelmeers/",
        date: "May 22, 2023",
        image: require("../assets/images/blog-pe.png"),
    },
    {
        title: "Time management",
        platform: platforms.LinkedIn,
        url: "https://www.linkedin.com/pulse/time-management-jeroen-egelmeers/",
        date: "May 03, 2022",
        image: require("../assets/images/blog-pe.png"),
    },
    {
        title: `Back to "normal", let's collaborate!`,
        platform: platforms.LinkedIn,
        url: "https://www.linkedin.com/pulse/back-normal-lets-collaborate-jeroen-egelmeers/",
        date: "Apr 20, 2022",
        image: require("../assets/images/blog-pe.png"),
    },
    {
        title: `What developers can learn from (book) authors`,
        platform: platforms.LinkedIn,
        url: "https://www.linkedin.com/pulse/what-developers-can-learn-from-book-authors-jeroen-egelmeers/",
        date: "May 24, 2021",
        image: require("../assets/images/blog-pe.png"),
    },
    {
        title: `Your review strategy sucks!`,
        platform: platforms.LinkedIn,
        url: "https://www.linkedin.com/pulse/your-review-strategy-sucks-jeroen-egelmeers/",
        date: "Apr 20, 2021",
        image: require("../assets/images/blog-pe.png"),
    },
    {
        title: `LinkedIn for developers`,
        platform: platforms.LinkedIn,
        url: "https://www.linkedin.com/pulse/linkedin-developers-jeroen-egelmeers/",
        date: "Sep 16, 2020",
        image: require("../assets/images/blog-pe.png"),
    },
    {
        title: `Full Stack is een grap!`,
        platform: platforms.Sogeti,
        url: "https://www.sogeti.nl/nieuws/digital/blogs/full-stack-een-grap",
        date: "Aug 13, 2020",
        image: require("../assets/images/blog-pe.png"),
    },
    {
        title: `"Full Stack is a joke!"`,
        platform: platforms.LinkedIn,
        url: "https://www.linkedin.com/pulse/full-stack-joke-jeroen-egelmeers/",
        date: "Jun 25, 2020",
        image: require("../assets/images/blog-pe.png"),
    }
]
