import React, {useEffect} from 'react';
import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "./components/Header";
import Services from "./components/Services";
import AboutMe from "./components/AboutMe";
import Copyright from "./components/Copyright";
import Footer from "./components/Footer";
import Companies from "./components/Companies";
import Blogs from "./components/Blogs";
import CountUp from "react-countup";
import VisibilitySensor from 'react-visibility-sensor';
import {events} from "./objects/events";
import {blogs} from "./objects/blogs";

function App() {
    const companies = [
        {
            src: require("./assets/images/logos/sogeti.png"),
            alt: "Sogeti",
            url: "https://www.sogeti.com/"
        },
        {
            src: require("./assets/images/logos/bovemij.png"),
            alt: "Bovemij",
            url: "https://www.bovemij.nl/"
        },
        {
            src: require("./assets/images/logos/asml.png"),
            alt: "ASML",
            url: "https://www.asml.com/"
        },
        {
            src: require("./assets/images/logos/philips.png"),
            alt: "Philips",
            url: "https://www.philips.nl/"
        },
        {
            src: require("./assets/images/logos/de-rechtspraak.png"),
            alt: "De rechtspraak",
            url: "https://www.rechtspraak.nl/"
        },
        {
            src: require("./assets/images/logos/achmea.png"),
            alt: "Achmea",
            url: "https://www.achmea.nl/"
        },
        {
            src: require("./assets/images/logos/planon.png"),
            alt: "Planon",
            url: "https://www.planon.nl/"
        },
        {
            src: require("./assets/images/logos/capgemini.png"),
            alt: "Capgemini",
            url: "https://www.capgemini.com/"
        },
        {
            src: require("./assets/images/logos/academy.png"),
            alt: "Capgemini Academy",
            url: "https://academy.capgemini.nl/en"
        },
        {
            src: require("./assets/images/logos/klm.png"),
            alt: "KLM",
            url: "https://www.klm.nl/"
        },
        {
            src: require("./assets/images/logos/abn.png"),
            alt: "ABN Amro",
            url: "https://www.abnamro.nl/"
        },
        {
            src: require("./assets/images/logos/asr.png"),
            alt: "ASR",
            url: "https://www.asr.nl/"
        },
        {
            src: require("./assets/images/logos/ns.png"),
            alt: "NS",
            url: "https://www.ns.nl/"
        },
    ];

    const conferences = [
        {
            src: require("./assets/images/logos/odsc.png"),
            alt: "ODSC",
            url: "https://odsc.com/speakers/supercharge-your-productivity-mastering-prompt-engineering/",
        },
        {
            src: require("./assets/images/logos/qxday.png"),
            alt: "QXDay",
            url: "https://www.sogeti.nl/events/qxday2024",
        },
        {
            src: require("./assets/images/logos/devoxx.png"),
            alt: "Devoxx",
            url: "https://devoxx.be/schedule/speaker-details/?id=4381",
        },
        {
            src: require("./assets/images/logos/jcon-world.png"),
            alt: "JCON World",
            url: "https://jconworld2023.sched.com/speaker/jeroen.egelmeers?iframe=yes&w=100%&sidebar=no&bg=dark",
        },
        {
            src: require("./assets/images/logos/prompt-engineering-conference.png"),
            alt: "Prompt Engineering Conference",
            url: "https://2023.promptengineering.rocks/",
        },
        {
            src: require("./assets/images/logos/front-mania.png"),
            alt: "Frontmania",
            url: "https://frontmania.com/programme/#",
        },
        {
            src: require("./assets/images/logos/carrierebeurs.png"),
            alt: "Carrierebeurs",
            url: "https://mijn.carrierebeurs.nl/sprekers/jeroen-egelmeers/item41410",
        },
        {
            src: require("./assets/images/logos/jfall.png"),
            alt: "J-Fall",
            url: "https://nljug.org/wp-content/uploads/2021/12/JAM04.pdf",
        },
        {
            src: require("./assets/images/logos/microsoft.jpg"),
            alt: "Microsoft Envision",
            url: "https://pulse.microsoft.com/nl-nl/microsoft-envision/",
        }
        ]

    useEffect(() => {
        document.title = "Jeroen Egelmeers | Prompt Engineering Advocate | Public Speaker"
    }, []);

    // filter events only the last three upcoming events (or otherwise also show the last past event)
    const pastEvent = (date: string) => {
        const eventDate = (new Date(date)).setHours(0, 0, 0, 0);
        const currentDate = (new Date()).setHours(0, 0, 0, 0);
        return eventDate < currentDate;
    }

    // Separate the events into upcoming and past events
    const upcomingEvents = events.filter(event => !pastEvent(event.date));
    const pastEvents = events.filter(event => pastEvent(event.date));

    // Sort the events by date
    upcomingEvents.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    pastEvents.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

    // Get the last three upcoming events
    let lastThreeEvents = upcomingEvents.slice(0, 3);

    // If there are less than three upcoming events, fill the remaining slots with past events
    if (lastThreeEvents.length < 3) {
        lastThreeEvents = [...lastThreeEvents, ...pastEvents.slice(0, 3 - lastThreeEvents.length)];
    }

    const getAndRoundDownTotalBlogs = () => {
        // Round down to the nearest 10
        console.log(blogs.length);
        return Math.floor(blogs.length / 10) * 10;
    }

    const getAndRoundDownTotalConfSessions = () => {
        let totalConfSessions = 0;

        for (let event of events) {
            if (event.multipleSessions) {
                totalConfSessions += event.multipleSessions;
            } else {
                totalConfSessions += 1;
            }
        }

        // Round down to the nearest 10
        return Math.floor(totalConfSessions / 10) * 10;
    }

  return (
    <div className="App flex flex-col min-h-screen">
        <Header />

        <div className="clearfix"></div>
        <div className="flex-grow">
            <Companies images={companies} title={"Clients and companies I've proudly contributed to"} />
        </div>

        <AboutMe />

        <Services showMore={true} events={lastThreeEvents} title={"Upcoming events"} description={"Jeroen is actively speaking at conferences and events. Below is a list of upcoming events where you can find Jeroen speaking!"} />

        <h2 className={"text-5xl mb-16 font-bold"}>Stats</h2>

        <div className="flex flex-col md:flex-row justify-center items-center">
            <div className="max-w-[300px] mb-20 md:mr-20">
                <CountUp end={30} redraw={true} duration={8}>
                    {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                            <div className={"text-2xl"}>
                                <div><span className={"text-5xl font-bold"} ref={countUpRef} />+</div>
                                <span>workshops/training</span>
                            </div>
                        </VisibilitySensor>
                    )}
                </CountUp>
            </div>
            <div className="max-w-[300px] mb-20 md:mr-20">
                <CountUp end={getAndRoundDownTotalConfSessions()} redraw={true} duration={8}>
                    {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                            <div className={"text-2xl"}>
                                <div><span className={"text-5xl font-bold"} ref={countUpRef} />+</div>
                                <span>events/conferences</span>
                            </div>
                        </VisibilitySensor>
                    )}
                </CountUp>
            </div>
            <div className="max-w-[300px] mb-20 m-2">
                <CountUp end={getAndRoundDownTotalBlogs()} redraw={true} duration={8}>
                    {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                            <div className={"text-2xl"}>
                                <div><span className={"text-5xl font-bold"} ref={countUpRef} />+</div>
                                <span>Blogs</span>
                            </div>
                        </VisibilitySensor>
                    )}
                </CountUp>
            </div>
        </div>

        <Blogs title={"Latest blogs"} show={3} backgroundImage={true} />

        <div className="flex-grow">
            <Companies images={conferences} title={"Featured at notable events"} />
        </div>

        <Footer />
        <Copyright />
    </div>
  );
}

export default App;
